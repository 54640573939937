.modalevent {
  .body {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    h4 {
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      line-height: 30px;
      text-align: center;
    }
    p {
      text-align: center;
    }
  }

  .btn-primary2 {
    width: 160px;
    font-weight: bold;
    font-size: 16px;

    border-radius: 10px;
  }
}
