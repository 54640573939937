@import '../../styles/global.scss';

.container.home {
  display: block;
  .rs-carousel {
    margin-bottom: 90px;
    @include mobile {
      margin-bottom: 50px;
    }
    border-radius: 10px;
    height: auto;
    // height: 500px;
    aspect-ratio: 12/5;

    .rs-carousel-label-wrapper input:checked ~ label {
      background: $color-dark2 !important;
    }
  }
  .title {
    font-weight: 900;
    font-size: 46px;
    line-height: 56px;
    @include mobile {
      font-size: 30px;
      line-height: 40px;
    }
  }
  .subtitle {
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    line-height: 22px;
    margin: 32px auto 60px;
  }
  .search-input {
    display: flex;
    align-items: center;
    // flex-wrap: wrap;
    justify-content: space-evenly;
    & > input {
      box-shadow: none;
    }
    @include mobile {
      flex-direction: column;
      align-items: center;
    }
    .posisi {
      min-width: 377px;
      margin-right: 30px;
      @include mobile {
        width: 280px;
        margin-bottom: 10px;
      }
    }
    .daerah {
      margin-right: 30px;
      @include mobile {
        margin-bottom: 10px;
      }
      width: 220px;
    }

    .btn-cari {
      width: 200px;
      height: 50px;
      // @include mobile {
      //   display: none;
      // }
    }
  }

  .lowongan-terbaru {
    padding-top: 100px;
    @include mobile {
      padding-top: 50px;
    }
    h4.lowongan-terbaru-title {
      font-weight: 800;
      font-size: 24px;
      text-align: center;
      margin-bottom: 45px;
    }
    .cards-container {
      display: flex;
      justify-content: space-between;

      flex-wrap: wrap;
      @include miniLaptop {
        margin-left: 5px;
        margin-right: 5px;
        justify-content: space-evenly;
      }
    }
  }

  .artikel-terbaru {
    padding-top: 100px;

    h4 {
      font-weight: 800;
      font-size: 24px;
      text-align: center;
      margin-bottom: 45px;
    }
  }
  .event {
    padding-top: 50px;

    h4 {
      font-weight: 800;
      font-size: 24px;
      @include mobile {
        font-size: 20px;
      }
      text-align: center;
      margin-bottom: 40px;
    }
    .cards-event-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      @include miniLaptop {
        margin-left: 5px;
        margin-right: 5px;
        justify-content: space-evenly;
      }
    }
  }
  .papan {
    display: flex;
    padding-left: 70px;
    padding-right: 70px;
    padding-top: 40px;
    padding-bottom: 40px;
    background: linear-gradient(102.47deg, #041629 0%, #0e3552 100%);
    border-radius: 10px;
    justify-content: space-between;
    align-items: center;
    margin-top: 100px;
    @include mobile {
      padding: 20px 10px 20px 10px;
    }
    .kiri {
      color: white;
      h2 {
        font-weight: 800;
        font-size: 36px;
        @include mobile {
          font-size: 20px;
          line-height: 30px;
        }
      }
      p {
        color: white;
        font-weight: 600;
        font-size: 18px;
        @include mobile {
          font-size: 14px;
        }
      }
    }
    .btn-papan {
      width: 200px;
      padding: 18px;
      text-align: center;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      @include mobile {
        width: 150px;
        padding: 10px;
      }
    }
    // .rs-btn {
    //   width: 200px;
    //   padding-top: 20px;
    //   padding-bottom: 20px;
    //   @include mobile {
    //     width: 170px;
    //   }
    // }
  }
  .faq {
    padding-top: 100px;

    h4 {
      font-weight: 800;
      font-size: 24px;
      text-align: center;
      margin-bottom: 50px;
    }

    .rs-panel-default {
      background-color: #f5f5f5;
      margin-bottom: 25px;
      .rs-panel-heading {
        &::after {
          content: '';
          position: absolute;
          background-color: #ffb6a8;
          background-image: url('../../assets_css/award.svg');
          background-size: auto;
          background-repeat: no-repeat;
          background-position: center;
          top: 10px;
          left: 30px;
          width: 44px;
          height: 44px;
          border-radius: 50%;
          @include mobile {
            left: 10px;
          }
        }
        .rs-panel-title {
          margin-left: 80px;
          @include mobile {
            margin-left: 40px;
          }

          & > * {
            font-weight: 700;
            font-size: 20px;
            @include mobile {
              font-size: 16px;
              line-height: 30px;
              letter-spacing: 0.1px;
            }
          }
        }
      }
      p {
        font-weight: 600;
        margin-left: 80px;
        font-size: 16px;
        color: $color-gray;
      }
    }
    .img-faq {
      margin-left: 70px;
    }
  }
}
