@import '../../styles/global.scss';
.lowongan {
  background-color: #f5f7fb;
  .search-input {
    display: flex;
    justify-content: space-between;
    height: 100%;
    .rs-input {
      background-color: #ffffff;
      border: 1px solid #edeeee;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05);
    }
    .posisi {
      min-width: 395px;
      margin-right: 15px;
    }
    .daerah {
      width: 500px;
      margin-right: 15px;
    }
    .btn-cari {
      width: 178px;
    }
  }
  .content {
    margin-top: 70px;
    @include mobile {
      margin-top: 30px;
    }
    .filter {
      background: #ffffff;
      border-radius: 10px;
      padding-top: 23px;
      overflow-y: hidden;
      h5 {
        padding-left: 29px;
        font-weight: 800;
        font-size: 24px;
      }
      .filter-field {
        height: 85%;
        overflow-y: auto;
        font-weight: 600;
        font-size: 16px;
        .rs-panel {
          margin: 0 16px 15px 16px;
          background: #efefef;
          border-radius: 5px;
          .rs-panel-heading {
            padding: 15px;
          }
          .rs-panel-body {
            background: #ffffff;
          }
        }
      }

      .rs-panel-collapse {
        * {
          font-weight: bold;
          font-size: 14px;
          line-height: 18px;

          letter-spacing: 0.2px;
        }
      }
      .rs-slider-progress-bar {
        background-color: $color-green;
      }
      .rs-slider-handle::before {
        border-color: $color-green;
      }
    }
    .cards {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      @include miniLaptop {
        margin-left: 5px;
        margin-right: 5px;
        justify-content: space-evenly;
      }
    }
  }
}
