@import '../../styles/global.scss';

.container.eventdetail {
  background-color: #f5f7fb;
  .content {
    margin-right: 20px;
    margin-bottom: 20px;
    @include mobile {
      margin-right: 0;
    }
    background: #ffffff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05),
      -16px -20px 30px rgba(0, 0, 0, 0.02), 8px 12px 35px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    overflow-x: hidden;
    padding-bottom: 20px;
    img.img-cover {
      width: 100%;
      // object-fit: cover;
      max-height: 500px;
    }
    h2.title-event {
      font-style: normal;
      font-weight: 800;
      font-size: 24px;
      line-height: 140%;
      /* or 34px */

      text-align: center;
      margin: 30px 80px;
    }
    hr {
      margin: 30px;
    }
    h3.subtitle {
      margin-left: 30px;
      margin-bottom: 22px;
      font-style: normal;
      font-weight: 800;
      font-size: 20px;
      line-height: 140%;
    }
    .main-content {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 25px;
      margin: 30px;
    }
  }
  .kanan {
    background: #ffffff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05),
      -16px -20px 30px rgba(0, 0, 0, 0.02), 8px 12px 35px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    padding: 25px 26px 22px 32px;

    h4 {
      font-weight: 800;
      font-size: 18px;
      line-height: 23px;
      /* identical to box height */

      display: flex;
      align-items: center;
      letter-spacing: 0.3px;
      margin-bottom: 30px;
    }
    .label {
      justify-content: flex-start;
      margin-bottom: 12px;
    }
    .daftar {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
      margin-top: 20px;
    }
    .footer-card {
      span {
        margin-left: 10px;
        vertical-align: middle;
        font-weight: 600;
        font-size: 14px;
        color: $color-gray;
      }
    }
  }
}
