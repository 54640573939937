.label-icon {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.3px;
  img {
    margin-right: 16px;
  }
}
