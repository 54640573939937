@import '../../styles/global.scss';

.tracer-study {
  .rs-picker-menu {
    z-index: 1000 !important;
  }

  .cont {
    position: relative;
    h1.title {
      max-width: 380px;
      display: inline-block;
      font-weight: 900;
      font-size: 46px;
      line-height: 70px;
      /* or 153% */

      letter-spacing: 0.2px;
      margin-bottom: 14px;
      @include mobile {
        text-shadow: 0 0 3px #ffffff, 0 0 5px $color-dark2;
      }
    }
    p {
      max-width: 640px;
      font-weight: 600;
      font-size: 16px;
      line-height: 30px;
      /* or 187% */
      @include mobile {
        text-shadow: 0 0 3px #ffffff, 0 0 5px $color-dark2;
      }

      letter-spacing: 0.3px;
    }
    .form-tracer {
      margin-top: 20px;
      padding: 15px 0px 15px 30px;
      max-width: 850px;
      // opacity: 0.5;
      background-color: #f5f7fb;
      .form-submit {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        @include mobile {
        }
      }
    }
    img {
      position: absolute;
      right: 0;
      top: 0;
      z-index: -1;
    }
  }
}

//
