@import '../../styles/global.scss';

.container.event {
  background-color: #f5f7fb;
  .rs-carousel {
    border-radius: 10px;
    width: auto;
    height: auto;
    // height: 500px;
    height: auto;
    aspect-ratio: 12/5;
    .rs-carousel-label-wrapper input:checked ~ label {
      background: $color-dark2 !important;
    }
  }

  .content {
    margin-top: 30px;

    .filter-side {
      margin-right: 20px;
      @include mobile {
        margin-right: 0;
      }
      margin-bottom: 30px;

      h2.title {
        font-weight: 800;
        font-size: 24px;
        line-height: 30px;
        /* identical to box height, or 125% */

        letter-spacing: 0.2px;
      }
    }
    .cards-event-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      @include miniLaptop {
        margin-left: 5px;
        margin-right: 5px;
        justify-content: space-evenly;
      }
    }
  }
}
