@import '../../styles/global.scss';

.card {
  position: relative;
  background-color: white;
  width: 353px;
  height: 275px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05),
    -16px -20px 30px rgba(0, 0, 0, 0.02), 8px 12px 35px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  padding: 25px 30px 20px 25px;
  letter-spacing: 0.3px;
  transition: all 0.5s ease;
  cursor: pointer;
  margin-bottom: 20px;
  .bookmark {
    position: absolute;
    top: 20px;
    right: 20px;
    .booking {
      animation-name: spin;
      animation-duration: 500ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;

      @keyframes spin {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
    }
  }
  &:hover {
    transform: scale(1.06);
  }
  .header-card {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
    .img-card {
      width: 50px;
      height: 50px;
      margin-right: 10px;
    }
    .title {
      width: 195px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      h3 {
        font-weight: 800;
        font-size: 18px;
        line-height: 23px;
      }
      h4 {
        font-weight: 600;
        font-size: 14px;
      }
    }
  }
  .content-card {
    ul {
      list-style: none;
      padding: 0;
      padding-left: 4px;
      li {
        margin-bottom: 10px;
        img {
          margin: 0;
        }
        span {
          margin-left: 10px;
          font-weight: bold;
          font-size: 14px;
          vertical-align: middle;
        }
      }
    }
  }
  hr {
    margin-bottom: 9px;
  }
  .footer-card {
    span {
      margin-left: 10px;
      vertical-align: middle;
      font-weight: 600;
      font-size: 14px;
      color: $color-gray;
    }
  }
}
