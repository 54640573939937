.kelola-akun {
  background: #f5f7fb;

  .main {
    padding: 20px 30px 30px 30px;
    /* White */

    background: #ffffff;
    /* Grey Bold */

    border: 1px solid #edeeee;
    box-sizing: border-box;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
  }
}
