@import '../../styles/global.scss';
.artikel-home {
  cursor: pointer;
  transition: all 0.5s ease;
  &:hover {
    transform: scale(1.03);
  }
  text-decoration: none;
}
.leftt {
  img {
    border-radius: 10px;
    width: 100%;
    // height: 100%;
    max-height: 440px;

    // max-width: 543px;
  }
  .badge {
    margin-top: 29px;
    // @include mobile {
    //   padding: 0;
    // }
  }
  .artikel-title {
    // cursor: progress;
    margin-top: 20px;
    font-weight: 800;
    font-size: 35px;
    line-height: 130%;
    @include mobile {
      font-size: 28px;
    }
  }
  .timeline {
    margin-top: 15px;
    font-weight: 700;
    font-size: 16px;
    color: $color-gray;
    & > * {
      color: $color-gray;
    }
    span {
      margin-left: 10px;
    }
  }
  @include mobile {
    margin-bottom: 30px;
  }
}
.rightt {
  padding-left: 20px;
  @include mobile {
    padding-left: 0;
  }
  // background-color: red;

  .rs-row {
    // display: flex;
    margin-bottom: 30px;
    // align-items: center;
    .img-right {
      width: 100%;
      border-radius: 10px;
      // object-fit: cover;
      max-height: 200px;
      margin-bottom: 20px;
    }
    .right-right {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      // align-items: center;
      .badge {
        margin-bottom: 27px;
        @include mobile {
          margin-bottom: 14px;
        }
      }
      .artikel-title {
        font-weight: 800;
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 17px;
        @include mobile {
          font-size: 14px;
        }
      }
      .timeline {
        font-weight: 700;
        font-size: 14px;
        color: $color-gray;
        & > * {
          color: $color-gray;
        }
        span {
          margin-left: 10px;
        }
      }
    }
  }
}
