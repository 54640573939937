@import '../../styles/global.scss';
.card-event {
  width: 353px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05),
    -16px -20px 30px rgba(0, 0, 0, 0.02), 8px 12px 35px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  letter-spacing: 0.3px;
  padding-bottom: 15px;
  margin-bottom: 20px;

  .image {
    width: 100%;
    position: relative;
    img {
      width: 100%;
      height: 213px;
      object-fit: cover;
      border-radius: 10px 10px 0 0;
    }
    .badge {
      position: absolute;
      left: 20px;
      top: 20px;
    }
  }
  .card-event-title {
    margin-top: 14px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 30px;
    font-weight: 800;
    font-size: 24px;
  }
  .detail {
    margin-left: 20px;
    margin-right: 20px;
    color: $color-gray;
    display: flex;
    justify-content: space-between;
    .satu {
      // margin-right: 60px;
      span {
        vertical-align: middle;
        font-weight: 700;
      }
    }
    .dua {
      span {
        font-weight: 700;
        vertical-align: middle;
      }
    }
    img {
      margin-right: 10px;
    }

    span {
      color: $color-gray;
    }
  }
}
