@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import 'utility.scss';
@import 'global.scss';
@import 'override.scss';

* {
  font-family: 'Mulish', sans-serif;
  color: $color-dark;
  font-weight: 600;
}
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #ffffff;
}

.container {
  padding-top: 150px;
  padding-bottom: 70px;
  padding-right: 5px;
  padding-left: 5px;
  min-height: calc(100vh - 80px);
  @include mobile {
    padding-top: 110px;
    padding-bottom: 50px;
    padding-right: 15px;
    padding-left: 15px;
    min-height: calc(100vh - 80px);
  }
}

* {
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: $color-dark2;
  }

  &::-webkit-scrollbar-thumb {
    background: $color-gray;
  }
}

.fw-400 {
  font-weight: 400;
}
.fw-600 {
  font-weight: 600;
}
.fw-700 {
  font-weight: 700;
}
.fw-800 {
  font-weight: 800;
}
.fw-900 {
  font-weight: 900;
}

.fm-inter {
  font-family: 'Inter', sans-serif;
}
.fm-roboto {
  font-family: 'Roboto', sans-serif;
}

.text-gray {
  color: $color-gray;
}

.badge {
  font-size: 14px;

  display: inline-block;
  padding: 6px 30px 6px 30px;
  color: white;
  background: linear-gradient(102.47deg, #299ba5 0%, #3dd0c0 100%);
  border-radius: 5px;

  &.bg-yellow {
    background: #f2c94c;
  }
  &.bg-blue {
    background: #2d9cdb;
  }
  &.bg-green {
    background: #6fcf97;
  }
  &.bg-red {
    background: #eb5757;
  }
}

.btn-transparan {
  background: none;
}

.loader-page {
  text-align: center;
}

.filter-side {
  padding: 15px;

  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      font-weight: bold;
      font-size: 16px;
      line-height: 25px;
      padding: 15px;
      cursor: pointer;
      border-radius: 5px;
      display: flex;
      align-items: center;
      img {
        margin-right: 10px;
      }
      &.active {
        font-weight: 800;
        font-size: 16px;
        line-height: 25px;
        background-color: #edeeee;
      }
      &:hover {
        font-weight: 800;
        font-size: 16px;
        line-height: 25px;
      }
    }
  }
}

.btn-pusk {
  width: 100%;
  padding: 12px 20px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height, or 137% */

  letter-spacing: 0.3px;
  color: $color-gray;
  background: #f8f9fd;
  text-align: left;
  border-radius: 10px;
  display: flex;
  align-items: center;
}
