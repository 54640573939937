.btn-edit {
  font-weight: bold;
  font-size: 14px;
  line-height: 35px;
  background: none;
  display: flex;
  align-items: center;
  span {
    margin-left: 10px;
  }
}
