.perusahaan-detail {
  background: #f5f7fb;
  .header {
    padding-bottom: 30px;
    background: #ffffff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05),
      -16px -20px 30px rgba(0, 0, 0, 0.02), 8px 12px 35px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 30px;
    .img-wrapper {
      height: 230px;
      width: 100%;
      margin-bottom: 25px;
    }
    .share {
      display: flex;
      justify-content: flex-end;
      margin-left: 32px;
      margin-right: 32px;
      .share-icon {
        border-radius: 50%;
        background: #eeecec;
        text-align: center;
        line-height: 50px;
        height: 40px;
        width: 40px;
        margin-left: 20px;
      }
    }
    .header-title {
      padding-left: 30px;
      display: flex;
      align-items: center;
      .img-logo {
        width: 187px;
        height: 187px;
        border-radius: 37px;
      }
      .kanan {
        margin-left: 41px;
        h2 {
          font-style: normal;
          font-weight: 800;
          font-size: 36px;
          line-height: 140%;
        }
        ul {
          list-style: none;
          padding: 0;
          display: flex;
          flex-wrap: wrap;
          padding-right: 280px;
          li {
            margin-right: 35px;
            margin-top: 27px;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;

            letter-spacing: 0.3px;
          }
        }
      }
    }
  }
  .tentang {
    margin-bottom: 30px;
    padding: 43px 45px 45px 30px;
    background: #ffffff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05),
      -16px -20px 30px rgba(0, 0, 0, 0.02), 8px 12px 35px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    h2.title {
      font-weight: 800;
      font-size: 20px;
      line-height: 140%;
      margin-bottom: 25px;
    }
    p {
      font-size: 18px;
      line-height: 31px;
    }
  }
  .list-pekerjaan {
    background: #ffffff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05),
      -16px -20px 30px rgba(0, 0, 0, 0.02), 8px 12px 35px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    padding: 43px 15px 43px 15px;
    h2.title {
      font-weight: 800;
      margin-left: 15px;
      font-size: 20px;
      line-height: 140%;
    }
    ul.cards-lowonganku {
      list-style: none;
      padding: 0;
      li {
        position: relative;
        padding: 10px 15px;
        border-radius: 10px;
        width: 100%;
        &:hover {
          background: #efefef;
          cursor: pointer;
        }
        h3.subtitle {
          font-weight: 800;
          font-size: 18px;
          line-height: 23px;
          letter-spacing: 0.3px;
          margin-bottom: 20px;
        }
      }
      .badge-jadwal {
        background: #ebf7ee;
        border: 1px solid #c2e5cb;
        &.tutup {
          background: #f7bbbb;
          border: 1px solid #ffb6b9;
        }
        box-sizing: border-box;
        border-radius: 10px;
        position: absolute;
        width: 192px;
        height: 40px;
        top: 12px;
        right: 11px;
        display: flex;
        justify-content: center;
        * {
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
          /* identical to box height */

          letter-spacing: 0.3px;
        }
      }
    }
  }
}
