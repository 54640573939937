@import '../../styles/global.scss';
.nav {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 80px;
  width: 100%;
  color: white;
  background-color: $color-dark2;
  font-size: 16px;
  font-weight: 700;
  position: fixed;
  z-index: 10;
  @include mobile {
    display: flex;
    height: 60px;
    justify-content: space-between;
  }

  .logo {
    margin-left: 40px;
    color: $color-green;
    font-size: 30px;
    text-decoration: none;
    margin-right: 40px;
    @include mobile {
      margin-left: 30px;
      img {
        width: 60px;
      }
    }
  }
  .collapse {
    display: flex;
    width: 100%;
    justify-content: space-between;
    @include mobile {
      z-index: 3;
      width: 100%;
      height: calc(100vh - 59px);
      // height: 100vh;
      position: fixed;
      flex-direction: column-reverse;
      justify-content: flex-end;
      background-color: $color-dark2;
      right: -100%;
      top: 59px;
      overflow-y: scroll;
      transition: all 0.4s ease-out;

      &.active {
        right: 0;
      }
    }
    .left {
      ul {
        list-style: none;
        padding: 0;
        list-style-type: none;
        display: flex;
        align-items: center;
        margin-bottom: 0;
        height: 100%;
        @include mobile {
          // display: none;
          flex-direction: column;
          font-size: 20px;
          margin-left: 0;
        }
        li {
          margin-right: 30px;
          @include mobile {
            margin-top: 40px;
            margin-right: 0;
          }
        }
      }
    }
    .right {
      margin-right: 40px;
      display: flex;
      align-items: center;
      @include mobile {
        justify-content: center;
        // display: none;
        margin-right: 0;
      }
      .img-profile {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-size: cover;
      }
      .rs-btn-subtle {
        font-weight: bold;
        font-size: 14px;
        line-height: 24px;
        color: white;
      }
      .rs-btn-subtle.rs-btn-loading:hover,
      .rs-btn-subtle.rs-btn-loading:not(.rs-btn-disabled):hover,
      .rs-btn-subtle.rs-btn-loading:focus,
      .rs-btn-subtle.rs-btn-loading:active,
      .rs-btn-subtle.rs-btn-loading:hover:active {
        color: white;
        background-color: transparent;
      }
      .rs-btn-subtle:focus,
      .rs-btn-subtle.rs-btn-focus,
      .rs-btn-subtle:hover {
        color: white;
        background: none;
      }
      .rs-dropdown-toggle-caret {
        display: inline-block;
        margin-left: 2px;
        position: relative;
        top: 0;
        right: 0;
        left: 10px;
        font-weight: normal;
        color: white;
      }
      .rs-dropdown .rs-dropdown-menu {
        min-width: 150px;
        -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.06),
          0 4px 4px rgba(0, 0, 0, 0.12);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.06), 0 4px 4px rgba(0, 0, 0, 0.12);
        @include mobile {
          position: absolute;
          z-index: 100;
          top: 50px;
          left: -70px;
          right: auto;
        }
      }

      // asdasd
      .rs-dropdown-menu {
        padding-left: 10px;
        padding-right: 10px;
        .rs-dropdown-item-content {
          font-weight: bold;
          font-size: 14px;
          color: $color-dark;
          border-radius: 5px;
          padding-top: 10px;
          padding-bottom: 10px;
          &:hover {
            text-decoration: none;
            background-color: #efefef;
          }
        }
      }
      .rightlink {
        margin-right: 21px;
        @include mobile {
          margin-left: 20px;
          margin-right: 20px;
          // display: none;

          font-size: 20px;
        }
      }
    }
  }

  .hamburger {
    display: none;
    margin-right: 30px;
    @include mobile {
      display: block;
      width: 25px;
      height: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      cursor: pointer;
      span {
        width: 100%;
        height: 3px;
        background-color: white;
        transform-origin: left;
        transition: all 0.4s ease;
      }
    }
  }
  &.collapsed {
    background-color: $color-dark2;
    color: white;

    .hamburger {
      span {
        &:first-child {
          background-color: white;
          transform: rotate(45deg);
        }
        &:nth-child(2) {
          opacity: 0;
        }

        &:last-child {
          background-color: white;
          transform: rotate(-45deg);
        }
      }
    }
  }
}
.link-nav {
  color: white;

  &:hover {
    color: $color-green !important;
  }

  &.active {
    color: $color-green;
    font-weight: 900;
  }
}
.masuk {
  padding-left: 30px;
  padding-right: 30px;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
}
.btn-logout {
  background: none;
  border: none;
  padding: 0;
}
