$color-dark: #0f243d;
$color-dark2: #0e3552;
$color-gray: #737b7d;
$color-green: #3dd0c0;

$width: 992px;
$width2: 1200px;

@mixin mobile {
  @media (max-width: #{$width}) {
    @content;
  }
}
@mixin miniLaptop {
  @media (max-width: #{$width2}) {
    @content;
  }
}
