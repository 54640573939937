@import '../../styles/global.scss';

.rs-modal-dialog {
  background-color: #f5f7fb;
}
.me {
  .left {
    padding-right: 20px;
    @include mobile {
      padding-right: 0;
    }
    .profile {
      margin-bottom: 30px;
      background: white;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05),
        -16px -20px 30px rgba(0, 0, 0, 0.02), 8px 12px 35px rgba(0, 0, 0, 0.06);
      border-radius: 10px;
      display: flex;
      padding-left: 30px;
      padding-top: 18px;
      padding-right: 20px;
      display: flex;
      @include mobile {
        // align-items: center;
        flex-direction: column;
      }
      .pp {
        .img-wrapper {
          &:hover {
            // background-color: red;
            opacity: 0.4;
          }
        }
      }
      .second {
        margin-left: 25px;
        @include mobile {
          margin-left: 0;
        }
        h6 {
          font-weight: 800;
          font-size: 14px;
          line-height: 18px;
          &.title {
            font-weight: bold;
            font-size: 20px;
            line-height: 140%;
            margin-top: 15px;
            & ~ p:first-child {
              margin-bottom: 30px;
            }
          }
        }
        p {
          margin-bottom: 25px;
          font-weight: 600;
          font-size: 16px;
          line-height: 30px;
          /* identical to box height, or 137% */

          letter-spacing: 0.3px;
        }
        span {
          display: flex;
          align-items: center;
          font-weight: bold;
          font-size: 14px;
          line-height: 35px;
        }
      }
      .third {
        margin-left: 50px;
        margin-top: 18px;
        @include mobile {
          margin-left: 0;
          margin-top: 0;
        }
        span {
          display: inline-block;
          margin-top: 10px;
          margin-bottom: 45px;
        }
        h6 {
          font-weight: 800;
          font-size: 14px;
          line-height: 18px;
        }
        p {
          margin-bottom: 25px;
          font-weight: 600;
          font-size: 16px;
          line-height: 30px;
          /* identical to box height, or 137% */

          letter-spacing: 0.3px;
        }
      }
    }
    .detail {
      background: #ffffff;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05),
        -16px -20px 30px rgba(0, 0, 0, 0.02), 8px 12px 35px rgba(0, 0, 0, 0.06);
      border-radius: 10px;
      padding: 28px 30px;
      .card-detail {
        margin-bottom: 44px;
        .badge {
          border-radius: 50px;
          margin-right: 15px;
          margin-bottom: 15px;
        }
        .cv {
          & > span {
            display: inline-block;
            width: 200px;
            margin-right: 160px;
            margin-bottom: 20px;
          }
          & > * {
            font-size: 18px;
            font-weight: bold;
            line-height: 22px;
            letter-spacing: 0.3px;
          }
        }
      }
      .title {
        margin-bottom: 21px;
        font-weight: 800;
        font-size: 20px;
        line-height: 140%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      p.aboutme {
        font-weight: normal;
        font-size: 16px;
        line-height: 35px;
      }
    }
  }
  .right {
    @include mobile {
      margin-top: 20px;
    }
    .status {
      background: #ffffff;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05),
        -16px -20px 30px rgba(0, 0, 0, 0.02), 8px 12px 35px rgba(0, 0, 0, 0.06);
      border-radius: 10px;
      padding: 25px 32px 32px 32px;
      h2 {
        font-family: Mulish;
        font-weight: 800;
        font-size: 18px;
        line-height: 23px;
        letter-spacing: 0.3px;
        margin-bottom: 19px;
      }
      .btn-ganti {
        justify-content: space-between;
        margin-bottom: 30px;
      }
      .btn-scs {
        background-color: #ebf7ee;
      }
      .btn-err {
        background-color: #fcedea;
      }
      .btn-cv {
        margin-bottom: 30px;
        img {
          margin-right: 10px;
        }
      }
      .btn-ass {
        margin-bottom: 30px;
        img {
          margin-right: 10px;
        }
      }
      .btn-email {
        img {
          margin-right: 10px;
        }
      }
    }
  }
}
