@import '../../../styles/global.scss';

.container.kelola-pelamar {
  background-color: #f5f7fb;

  .atas {
    margin-bottom: 40px;
    h5 {
      font-weight: 800;
      font-size: 18px;
      line-height: 300%;
    }
    .white {
      width: 330px;
      // .rs-btn {
      //   padding: 20px;
      // }
      .rs-picker-toggle-value {
        font-weight: 800;
      }
    }
  }
  .bawah {
    .filter-side {
      background: none;
      border: none;
      box-shadow: none;
      li.active {
        padding: 10px;
        margin: 5px;
        font-weight: 800;
        font-size: 16px;
        line-height: 25px;
        background-color: #ffffff;
        border: 1px solid #edeeee;
        box-sizing: border-box;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05);
        border-radius: 5px;
      }
    }
    .top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      .btn-filter {
        .rs-btn {
          width: 228px;
          background: #ffffff;
          box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05);
          border-radius: 10px;
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
          color: $color-gray !important;
          text-align: left;
          line-height: 34px;
          * {
            color: $color-gray !important;
          }
        }

        /* White */
      }
    }
  }
}
