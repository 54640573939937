@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap');
@import '../../styles/global.scss';
.footer {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  // clear: both;
  // height: 200px;
  width: 100%;
  min-height: 80px;
  // height: 200px;
  // margin-top: -80px;
  color: white;
  padding: 28px 20px;
  background-color: $color-dark2;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  @include mobile {
    padding: 10px 10px;
  }
}
