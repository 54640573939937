@import '../../styles/global.scss';
.link-alt {
  color: $color-dark;
  &:hover {
    color: $color-dark;
    font-weight: 700;
  }
}
// .link-drop {
//   font-weight: bold;
//   font-size: 14px;
//   line-height: 18px;
//   color: $color-dark;
//   &:hover {
//     text-decoration: none;
//   }
// }
