@import '../../styles/global.scss';

.container.survey {
  .list-pertanyaan {
    background: #ffffff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05),
      -16px -20px 30px rgba(0, 0, 0, 0.02), 8px 12px 35px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    padding: 12px 29px;
    .title {
      font-weight: 800;
      font-size: 18px;
      line-height: 30px;
    }
    .cont-list {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        text-decoration: none;
        padding: 15px 10px;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        &.active {
          background: #efefef;
          border-radius: 5px;
        }
      }
    }
  }
  .detail-pertanyaan {
    padding: 20px 30px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05),
      -16px -20px 30px rgba(0, 0, 0, 0.02), 8px 12px 35px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    .btn-bwh {
      padding: 14px 25px;
      border-radius: 10px;
      margin-top: 40px;
      font-weight: bold;
      font-size: 16px;
      color: white;
      @include mobile {
        padding: 5px 10px;
        font-size: 13px;
      }
    }
    .status-info {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      margin-bottom: 30px;
    }
    .note {
      width: 100%;
      padding: 10px;
      background-color: #fdf8cc;
      font-weight: 600;
      font-style: italic;
      border-radius: 10px;
    }
    .pertanyaan {
      font-weight: 800;
      font-size: 18px;
      line-height: 30px;
      margin-top: 10px;
      margin-bottom: 60px;
    }
    .list-jawaban {
      .jawaban {
        // min-width: 340px;
        min-height: 50px;

        background: #efefef;
        border-radius: 10px;
        padding: 14px 22px;
        cursor: pointer;
        margin-bottom: 20px;
        display: flex;
        span {
          font-weight: 800;
          font-size: 16px;
          line-height: 22px;
          vertical-align: middle;
        }
        img {
          margin-right: 20px;
        }

        &.active {
          span {
            color: white;
          }
          background: #6fcf97;
        }
      }
    }
  }
  .prolog {
    padding: 20px 30px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05),
      -16px -20px 30px rgba(0, 0, 0, 0.02), 8px 12px 35px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    .title {
      font-weight: 800;
      font-style: italic;
      font-size: 20px;
      border-radius: 10px;
    }
  }
}
