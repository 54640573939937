@import '../../styles/global.scss';
.container.lowonganku {
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    h2.title {
      font-style: normal;
      font-weight: 800;
      font-size: 20px;
      line-height: 140%;
    }
    .rs-input-group {
      width: 350px;
    }
  }

  .cards-container {
    display: flex;
    padding: 10px;

    flex-wrap: wrap;
    & > div {
      margin: 10px;
    }
  }
}
