@import '../../styles/global.scss';

.lowongan-detail {
  background-color: #f5f7fb;
  .main {
    padding-left: 32px;
    min-height: 305px;
    background: #ffffff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05),
      -16px -20px 30px rgba(0, 0, 0, 0.02), 8px 12px 35px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    display: flex;
    padding-top: 22px;
    padding-bottom: 22px;
    padding-right: 100px;
    margin-bottom: 20px;
    @include mobile {
      flex-wrap: wrap;
      padding-right: 30px;
    }

    .img-profile {
      width: 126px;
      img {
        width: inherit;
        padding-right: 32px;
        border-radius: 12px;
      }
    }
    .content {
      width: 100%;
      .title {
        font-weight: 800;
        font-size: 24px;
        line-height: 140%;
        color: $color-dark;
        display: block;
      }
      p.subtitle {
        margin-top: 7px;
        margin-bottom: 29px;
        display: inline-block;
        span {
          &:last-child {
            &::before {
              content: '';
              display: inline-flex;
              position: relative;
              left: -25px;
              width: 2px;
              height: 14px;
              bottom: -3px;

              background-color: #edeeee;
            }
          }
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          margin-right: 50px;

          letter-spacing: 0.3px;
        }
      }
      .list {
        display: flex;
        flex-wrap: wrap;
        & > span {
          margin-bottom: 20px;
        }
      }
      .ket-tambahan {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 33px;
        span {
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
          color: $color-gray;
          letter-spacing: 0.3px;
          display: flex;
          img {
            margin-right: 17px;
          }
        }
      }
    }
  }
  .deskripsi {
    padding-left: 32px;
    padding-top: 35px;
    padding-bottom: 40px;
    padding-right: 30px;
    background: #ffffff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05),
      -16px -20px 30px rgba(0, 0, 0, 0.02), 8px 12px 35px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    h2 {
      font-weight: 800;
      font-size: 20px;
      line-height: 140%;
      padding-bottom: 22px;
    }
    p {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 30px;
      margin-bottom: 70px;
    }
    .badges {
      margin-bottom: 70px;
      .badge {
        margin-bottom: 10px;
        border-radius: 50px;
        margin-right: 17px;
      }
    }
    .keuntungan {
      display: flex;
      flex-wrap: wrap;
      .poin {
        // min-width: 210px;
        @include mobile {
          // min-width: 80px;
        }
        margin-right: 40px;
        margin-bottom: 20px;
        span {
          padding-left: 9px;
          vertical-align: middle;
        }
      }
    }
  }
  .tentang {
    padding-top: 35px;
    padding-left: 32px;
    padding-right: 33px;
    padding-bottom: 25px;

    background: #ffffff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05),
      -16px -20px 30px rgba(0, 0, 0, 0.02), 8px 12px 35px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    h2 {
      font-family: 'Roboto', 'sans-serif';
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      margin-bottom: 32px;
      line-height: 140%;
    }
    .top {
      display: flex;
      @include mobile {
        flex-wrap: wrap;
      }
      .img-profile {
        width: 94px;
        margin-right: 32px;
        img {
          width: 64px;
          border-radius: 12px;
        }
      }
      .about-title {
        width: 100%;
        .title {
          font-weight: 800;
          font-size: 24px;
          line-height: 140%;
          color: $color-dark;
        }
        p.subtitle {
          margin-top: 7px;
          margin-bottom: 29px;
          display: inline-block;
          span {
            &:last-child {
              &::before {
                content: '';
                display: inline-flex;
                position: relative;
                left: -25px;
                width: 2px;
                height: 14px;
                bottom: -3px;

                background-color: #edeeee;
              }
            }
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            margin-right: 50px;

            letter-spacing: 0.3px;
          }
        }
      }
    }

    .content {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 31px;
      &:first-child {
        transition: all ease 1s;
      }
      a {
        display: block;
        margin-top: 10px;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 31px;
        color: $color-dark;
      }
    }
  }
  .upload-field {
    background: #ffffff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05),
      -16px -20px 30px rgba(0, 0, 0, 0.02), 8px 12px 35px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    padding: 25px 32px 25px 32px;
    margin-left: 30px;
    @include mobile {
      margin-top: 20px;
      margin-left: 0;
    }
    .file {
      color: $color-dark;
      font-size: 15px;
    }

    .rs-control-label {
      font-weight: 800;
      font-size: 18px;
      line-height: 23px;
      letter-spacing: 0.3px;
    }
    .rs-input {
      background: #f8f9fd;
      /* Grey Bold */

      border: 1px solid #edeeee;
      box-sizing: border-box;
      border-radius: 10px;
      box-shadow: none;
    }
    .rs-btn {
      padding: 10px 49px 10px 49px;
    }
  }
  .lowongan-lain {
    margin-top: 20px;
    margin-left: 30px;
    // height: 700px;
    // overflow-y: scroll;
    display: flex;
    flex-wrap: wrap;
    // flex-direction: column;
  }
  .share {
    margin-left: 60px;
    margin-right: 60px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .icon-share {
      display: inline-block;
      border-radius: 50%;
      height: 40px;
      width: 40px;
      background: #eeecec;

      text-align: center;
      line-height: 50px;
      margin-left: 16px;
      i::before {
        position: relative;
        left: 0;
      }
    }
  }
}
