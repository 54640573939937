$indent_1: 5px;
$indent_2: 10px;
$indent_3: 15px;
$indent_4: 20px;

.link-unstyled {
  &,
  &:visited,
  &:hover,
  &:active,
  &:focus,
  &:active:hover {
    text-decoration: none;
  }
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-disappear {
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.width-250 {
  width: 250px;
}

.width-200 {
  width: 200px;
}

.height-200 {
  height: 200px;
}

.height-220 {
  height: 220px;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.mw-100 {
  max-width: 100%;
}

.mh-100 {
  max-height: 100%;
}

.w-auto {
  width: auto;
}

.h-auto {
  height: auto;
}

.d-none {
  display: none;
}

.d-inline-block {
  display: inline-block;
}

.d-block {
  display: block;
}

.d-flex {
  display: flex;
}

.d-inline-flex {
  display: inline-flex;
}

.flex-column {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.align-items-center {
  align-items: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-center {
  justify-content: center;
}

.padded {
  padding: 10px;
}

.cursor-pointer {
  &:hover {
    cursor: pointer;
  }
}

.ws-nowrap {
  white-space: nowrap;
}

.br-circle {
  border-radius: 50%;
}

.font-bolder {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.italic {
  font-style: italic;
}

.word-break-all {
  word-break: break-all;
}

.font-huge {
  font-size: 100px;
}

.contain-scroll {
  overscroll-behavior: contain;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.p-0 {
  padding: 0;
}

.m-0 {
  margin: 0;
}

.mt-page {
  margin-top: 150px;
}

.mt-1 {
  margin-top: $indent_1;
}

.mt-2 {
  margin-top: $indent_2;
}

.mt-3 {
  margin-top: $indent_3;
}

.mb-1 {
  margin-bottom: $indent_1;
}

.mb-2 {
  margin-bottom: $indent_2;
}

.mb-3 {
  margin-bottom: $indent_3;
}
.mb-4 {
  margin-bottom: $indent_4;
}

.ml-1 {
  margin-left: $indent_1;
}

.ml-2 {
  margin-left: $indent_2;
}

.ml-3 {
  margin-left: $indent_3;
}

.mr-1 {
  margin-right: $indent_1;
}

.mr-2 {
  margin-right: $indent_2;
}

.mr-3 {
  margin-right: $indent_3;
}

.pt-1 {
  padding-top: $indent_1;
}

.pt-2 {
  padding-top: $indent_2;
}

.pt-3 {
  padding-top: $indent_3;
}

.pb-1 {
  padding-bottom: $indent_1;
}

.pb-2 {
  padding-bottom: $indent_2;
}

.pb-3 {
  padding-bottom: $indent_3;
}
