@import '../../styles/global.scss';

.artikel {
  .list-artikels {
    margin-top: 30px;

    .kategori-filter {
      margin: 0 auto 20px;
      padding: 21px 22px;
      width: 100%;
      min-height: 280px;

      background: #ffffff;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05),
        -16px -20px 30px rgba(0, 0, 0, 0.02), 8px 12px 35px rgba(0, 0, 0, 0.06);
      border-radius: 10px;
      h2 {
        padding-left: 10px;
        font-weight: 800;
        font-size: 24px;
      }
      hr {
        margin-top: 0;
      }
      ul {
        list-style: none;
        padding: 0;
        a {
          display: block;
          text-decoration: none;
          &.active {
            background-color: #edeeee;
            li {
              line-height: 25px;
              font-weight: 800;
              font-size: 16px;
            }
          }
          border-radius: 10px;
          padding: 10px 5px;
          text-decoration: none;
          font-size: 16px;
          color: $color-dark;
        }
      }
    }
    .rightt2 {
      .artikel-home {
        .img-right {
          width: 100%;
          border-radius: 10px;
          object-fit: cover;
          max-height: 200px;
          margin-bottom: 20px;
        }
        .right-right {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          // align-items: center;
          .badge {
            margin-bottom: 27px;
            @include mobile {
              margin-bottom: 14px;
            }
          }
          .artikel-title {
            font-weight: 800;
            font-size: 18px;
            line-height: 22px;
            margin-bottom: 17px;
            @include mobile {
              font-size: 14px;
            }
          }
          .timeline {
            font-weight: 700;
            font-size: 14px;
            color: $color-gray;
            & > * {
              color: $color-gray;
            }
            span {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
}
