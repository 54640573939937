@import '../../styles/global.scss';

.card-lamaranku {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include mobile {
    position: relative;
    margin-top: 10px;
  }
  .kiri {
    display: flex;
    align-items: center;
    img {
      width: 50px;
      height: 50px;
    }
    .kirii {
      margin-left: 20px;
      h3 {
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0.3px;
        margin-bottom: 5px;
        color: $color-dark;
      }
      h4 {
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        /* identical to box height, or 157% */

        letter-spacing: 0.3px;
        margin-bottom: 14px;
      }
      h5 {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        /* identical to box height, or 157% */

        letter-spacing: 0.3px;
        color: $color-gray;
      }
    }
  }
  .badge {
    border-radius: 50px;
    height: 30px;
    @include mobile {
      position: absolute;
      // opacity: 0.7;
      top: -29px;
      right: -20px;
    }
  }
}
