@import '../../../styles/global.scss';

.container.kpp {
  background: #f5f7fb;
  .top {
    margin-bottom: 20px;
    .banner {
      position: relative;
      border-radius: 10px;
      overflow: hidden;
      .img-cover {
        width: 100%;
        aspect-ratio: 48/10;
      }
      .btn-upload-banner {
        position: absolute;
        bottom: 10px;
        right: 10px;
        width: 160px;
        height: 50px;

        /* 2 */

        background: linear-gradient(102.47deg, #041629 0%, #0e3552 100%);
        border-radius: 10px;
        color: white;
        text-align: center;
        font-size: 16px;
        line-height: 50px;
        cursor: pointer;

        @include mobile {
          height: 30px;
          line-height: 30px;
          font-size: 11px;
          width: 100px;
          border-radius: 5px;
        }
      }
    }
  }

  .kanan {
    padding: 21px 30px;
    background: #ffffff;
    /* Grey Bold */

    border: 1px solid #edeeee;
    box-sizing: border-box;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
  }
}
