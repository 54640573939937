@import '../../styles/global.scss';

.signup {
  display: flex;
  align-items: center;

  .cont {
    display: flex;

    .left {
      @include mobile {
        position: fixed;
        opacity: 0.3;
        z-index: -3;
        display: flex;
        right: 10px;
        top: 100px;
        justify-content: flex-end;
        img {
          width: 70%;
        }
      }
    }

    .right {
      width: 100%;
      .rs-input {
        @include mobile {
          opacity: 0.9;
        }
      }
      .rs-radio-group.rs-radio-group-picker.rs-radio-group-inline {
        background: none;
        border: none;
      }
      .rs-radio-group-picker .rs-radio-checked .rs-radio-checker > label {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        background: #edeeee;
        border-radius: 10px;
        color: $color-dark;
      }
      h2 {
        font-size: 35px;
        margin-bottom: 16px;
      }
      h4 {
        font-size: 18px;
      }

      .pw {
        cursor: pointer;
        font-size: 20px;
      }
    }
  }
}
