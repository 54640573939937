@import '../../styles/global.scss';

.container.lamaranku {
  background: #f5f7fb;
  .filter-side {
    img {
      margin-right: 10px;
    }
  }
  .kanan {
    padding-left: 30px;
    @include mobile {
      padding-left: 0;
      padding-top: 20px;
    }
    .list-lamaranku {
      background: #ffffff;
      border-radius: 10px;
      padding: 26px 30px 10px 30px;
    }
  }
}
