@import '../../styles/global.scss';

.container.tentang-kami {
  background: #f5f7fb;
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .judul {
      font-style: normal;
      font-weight: 800;
      font-size: 46px;
      line-height: 56px;
      text-align: center;
      margin-bottom: 20px;
      @include mobile {
        font-size: 30px;
        margin-bottom: 5px;
      }
    }
    .subjudul {
      text-align: center;
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
      margin-bottom: 70px;
      @include mobile {
        font-size: 20px;
        margin-bottom: 40px;
      }
    }
    .cover {
      width: 100%;
      aspect-ratio: 48/15;
      border-radius: 10px;
    }
    .card-tentang {
      margin: 50px;
      padding: 40px 60px;
      @include mobile {
        padding: 20px 30px;
      }
      background: #ffffff;
      box-shadow: -16px -20px 30px rgba(0, 0, 0, 0.02);
      border-radius: 10px;
      min-width: 100%;
    }
  }
}
