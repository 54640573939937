@import '../../styles/global.scss';
.cardexp {
  h3 {
    font-weight: bold;
    font-size: 18px;
    line-height: 140%;
    margin-bottom: 15px;
    /* or 25px */
  }
  h4 {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.3px;
    margin-bottom: 6px;
  }
  h5 {
    font-size: 14px;
    line-height: 22px;
    color: $color-gray;
    font-weight: normal;
    letter-spacing: 0.3px;
  }
}
