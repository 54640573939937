.custom-table {
  background: #ffffff;
  /* Grey Bold */

  border: 1px solid #edeeee;
  box-sizing: border-box;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  .rs-checkbox-wrapper {
    top: 4px !important;
    left: 10px !important;
  }
  margin-bottom: 20px;
}

.bot {
  background: #ffffff;
  /* Grey Bold */

  border: 1px solid #edeeee;
  box-sizing: border-box;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 25px 18px;
  h4 {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 20px;
  }
  .btns {
    display: flex;
    flex-wrap: wrap;
    & > * {
      min-width: 160px;
      min-height: 40px;
      margin-bottom: 10px;
    }
    .green {
      margin-right: 20px;
      background: #6fcf97;
    }
    .red {
      margin-right: 100px;
      background: #eb5757;
    }
    .blue {
      margin-right: 20px;
      background: #2d9cdb;
    }
  }
}

.btn-danger {
  color: red;
  background: none;
  margin: 0;
  padding: 0;
  &:hover {
    font-weight: bold;
  }
}
