@import '../../styles/global.scss';

.signin {
  display: flex;
  align-items: center;
  .cont {
    display: flex;
    align-items: center;

    .left {
      margin-right: 50px;
      @include mobile {
        position: fixed;
        opacity: 0.3;
        z-index: -3;
        display: flex;
        right: 10px;
        top: 100px;
        justify-content: flex-end;
        img {
          width: 60%;
        }
      }
    }

    .right {
      width: 100%;
      h2 {
        font-size: 35px;
      }
      h4 {
        font-size: 18px;
      }

      .pw {
        cursor: pointer;
        font-size: 20px;
      }
      .rs-input {
        @include mobile {
          opacity: 0.9;
        }
      }
      .rs-checkbox-wrapper {
        left: 0px;
        top: 0px;
      }
    }
  }

  .rs-checkbox-checker {
    padding: 0;
    padding-left: 20px;
  }
}

.modal-forgot {
  position: absolute;
  // top: 30%;
  left: 50%;
  margin-left: -250px;
  top: 30%;
  width: 500px;
  @include mobile {
    width: 300px;
    margin-left: -150px;
  }
  // .rs-modal-dialog {
  //   // width: 500px;
  // }
}
