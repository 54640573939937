@import './global.scss';

// override

.rs-form-fluid {
  .rs-input-group {
    width: 100% !important;
  }
}

.rs-control-label {
  font-weight: 600;
  font-size: 16px;
}

.rs-input {
  display: block;
  width: 100%;
  color: $color-dark;
  background-color: #f5f5f5;
  background-image: none;
  border: 1px solid #f5f5f5;
  -webkit-transition: border-color ease-in-out 0.3s;
  transition: border-color ease-in-out 0.3s;
  border-radius: 5px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05),
    -16px -20px 30px rgba(0, 0, 0, 0.02), 8px 12px 35px rgba(0, 0, 0, 0.06);
  /* stylelint-disable */
  padding: 14px;
  font-size: 14px;
  line-height: 1.42857143;
  height: 50px/9;
  /* stylelint-enable */
  &::placeholder {
    color: $color-gray;
  }
}

.rs-input:focus,
.rs-input:hover {
  border-color: $color-gray;
  outline: 0;
}
/* Change the white to any color */
.rs-input:-webkit-autofill,
.rs-input:-webkit-autofill:hover,
.rs-input:-webkit-autofill:focus,
.rs-input:-webkit-autofill:active {
  // box-shadow: 0 0 0 30px #f5f5f5 inset !important;
  // -webkit-box-shadow: 0 0 0 30px #f5f5f5 inset !important;
  -webkit-text-fill-color: $color-dark !important;
  transition: background-color 5000s ease-in-out 0s;
}

// addon

.rs-input-group.rs-input-group-inside .rs-input-group-addon {
  bottom: 0;
  background: none;
  border: none;
}
.rs-input-group.rs-input-group-inside .rs-input-group-btn {
  bottom: 0;
  background: none;
  border: none;
  height: 100%;
}

// checkbox

.rs-checkbox .rs-checkbox-inner::before {
  border-color: $color-dark;
}

.rs-checkbox-checker:hover .rs-checkbox-wrapper .rs-checkbox-inner::before {
  border-color: $color-dark;
}
.rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner::before,
.rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner::before {
  border-color: $color-dark;
  background-color: $color-dark;
}

.rs-checkbox-wrapper [type='checkbox']:focus ~ .rs-checkbox-inner::before {
  -webkit-box-shadow: 0 0 0 0px rgba(217, 217, 217, 0.4);
  box-shadow: 0 0 0 0px rgba(217, 217, 217, 0.4);
}
:hover.rs-checkbox-wrapper
  [type='checkbox']:focus
  ~ .rs-checkbox-inner::before {
  -webkit-box-shadow: 0 0 0 0px $color-gray;
  box-shadow: 0 0 0 0px $color-gray;
}
.rs-checkbox-checked
  .rs-checkbox-wrapper
  [type='checkbox']:focus
  ~ .rs-checkbox-inner::before {
  -webkit-box-shadow: 0 0 0 0px $color-gray;
  box-shadow: 0 0 0 0px $color-gray;
}

// btn

// .rs-btn {

// }

.rs-btn-primary {
  /* stylelint-disable-next-line */
  background: linear-gradient(102.47deg, #041629 0%, #0e3552 100%);
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06),
    0px 2px 6px rgba(41, 155, 165, 0.04), 0px 0px 1px rgba(41, 155, 165, 0.04);
  color: #ffffff;

  border-radius: 5px;
  &.wide {
    padding: 14px 12px;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
  }
}

.rs-btn-primary:focus,
.rs-btn-primary.rs-btn-focus {
  color: #ffffff;
  background-color: $color-gray !important;
}
.rs-btn-primary:not(.rs-btn-disabled):hover {
  background-color: $color-gray !important;
}

.rs-btn.rs-btn-primary.rs-btn-disabled {
  background-color: $color-dark !important;
  opacity: 100;
  &:hover {
    background-color: $color-dark !important;
  }
}

.rs-btn-default {
  /* stylelint-disable-next-line */
  background: linear-gradient(102.47deg, #299ba5 0%, #3dd0c0 100%);
  color: #ffffff;
  font-weight: bold;
  &:hover {
    color: $color-dark2;
    font-weight: bold;
  }
}
.rs-btn-default:focus,
.rs-btn-default.rs-btn-focus {
  color: #ffffff !important;
  background-color: linear-gradient(
    102.47deg,
    #299ba5 0%,
    #3dd0c0 100%
  ) !important;
  &:hover {
    color: $color-dark2 !important;
  }
}
.rs-btn-default:not(.rs-btn-disabled):hover {
  background-color: linear-gradient(
    102.47deg,
    #299ba5 0%,
    #3dd0c0 100%
  ) !important;
}

.rs-btn.rs-btn-default.rs-btn-disabled {
  background-color: linear-gradient(
    102.47deg,
    #299ba5 0%,
    #3dd0c0 100%
  ) !important;
  opacity: 100;
  &:hover {
    background-color: linear-gradient(
      102.47deg,
      #299ba5 0%,
      #3dd0c0 100%
    ) !important;
  }
}

.rs-error-message-inner {
  color: red;
}
// .rs-error-message.rs-error-message-show {
//   background-color: red !important;
// }
.rs-loader-spin::before {
  border: 3px solid $color-dark2;
}
.rs-loader-spin::after {
  border-width: 3px;
  border-style: solid;
  border-color: #a6a6a6 transparent transparent;
  -webkit-animation: loaderSpin 0.6s infinite linear;
}
.rs-loader-content {
  float: left;
  display: inline-block;
  color: $color-dark2;
}

.rs-loader-wrapper.rs-loader-inverse {
  background-color: $color-dark2;
}

//tanggal

.rs-picker-toggle-value {
  color: $color-dark !important;
}
.rs-picker-toolbar-right-btn-ok {
  background-color: $color-green;
  &:hover {
    background-color: lightgreen !important;
  }
}

.rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-btn.active,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle.active {
  border-color: $color-dark2;
}
.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  border: 1px solid $color-green;
  padding: 4px;
}
.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  color: #ffffff;
  border-color: $color-green;
  background-color: $color-green;
}

.rs-picker-date.rs-picker-default .rs-picker-toggle {
  padding: 14px 52px 14px 14px !important;
  border-radius: 5px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05),
    -16px -20px 30px rgba(0, 0, 0, 0.02), 8px 12px 35px rgba(0, 0, 0, 0.06);
  background-color: #f5f5f5 !important;
  background-image: none;
  border: 1px solid #f5f5f5 !important;
  -webkit-transition: border-color ease-in-out 0.3s;
  transition: border-color ease-in-out 0.3s;

  /* stylelint-disable */
  .rs-picker-toggle-clean {
    top: 14px !important;
  }
  .rs-picker-toggle-caret {
    top: 14px !important;
  }
}

.rs-picker-input {
  padding: 14px;
  border-radius: 5px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05),
    -16px -20px 30px rgba(0, 0, 0, 0.02), 8px 12px 35px rgba(0, 0, 0, 0.06);
  background-color: #f5f5f5 !important;
  background-image: none;
  border: 1px solid #f5f5f5 !important;
  -webkit-transition: border-color ease-in-out 0.3s;
  transition: border-color ease-in-out 0.3s;

  &.white {
    background-color: #fff !important;
    border: 1px solid #edeeee !important;
    box-sizing: border-box;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
  }

  &:not(.rs-picker-tag) {
    &.rs-picker-default .rs-picker-toggle {
      padding: 0 !important;
      // width: 95% !important;

      background: none !important;

      .rs-picker-toggle-caret {
        top: 0 !important;
      }
      .rs-picker-toggle-value {
        width: 90%;
      }
      width: auto;
      left: 15px;
      right: 10px;
    }
    .rs-picker-search-input {
      padding: 0 !important;
    }
  }
  &.rs-picker-tag {
    padding: 7px !important;
    &.rs-picker-default .rs-picker-toggle {
      background: none !important;
    }
    .rs-picker-search-input {
      padding-left: 5px !important;
      // width: 100px !important;
    }
  }
}

.rs-radio-checker:hover .rs-radio-wrapper .rs-radio-inner::before {
  border-color: $color-dark2;
}
.rs-radio-checked .rs-radio-wrapper .rs-radio-inner::before {
  border-color: $color-dark2;
  background-color: $color-dark2;
}
.rs-radio-wrapper [type='radio']:focus ~ .rs-radio-inner::before {
  -webkit-box-shadow: none;
  box-shadow: none;
}
:hover.rs-radio-wrapper [type='radio']:focus ~ .rs-radio-inner::before {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.rs-radio-checked
  .rs-radio-wrapper
  [type='radio']:focus
  ~ .rs-radio-inner::before {
  -webkit-box-shadow: none;
  box-shadow: none;
}

// table
.rs-table-cell-header {
  .rs-table-cell-content {
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
  }
}

.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: $color-dark2;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
  line-height: 60px;
  @include mobile {
    right: 30px;
    width: 40px;
    height: 40px;
    bottom: 30px;
    font-size: 20px;
    line-height: 40px;
  }
}

.label-danger {
  color: red;
  font-weight: bold;
  font-style: italic;
}
