@import '../../../styles/global.scss';

.container.kelola-lowongan {
  background-color: #f5f7fb;
  h3.title {
    margin-top: 10px;
    margin-bottom: 60px;
    text-align: center;
    font-weight: 800;
    font-size: 20px;
    line-height: 140%;
  }
  .kanan {
    padding-left: 30px;
    @include mobile {
      margin-top: 20px;
      padding-left: 0;
    }
    .top {
      margin-bottom: 50px;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: space-between;
      .input-search {
        width: 350px;
      }
      .btn-tambah {
        display: flex;
        align-items: center;
        border-radius: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
        font-weight: 800;
        font-size: 14px;
      }
    }
    .mid {
      display: flex;
      justify-content: space-between;
      .btn-filter {
        .rs-btn {
          width: 228px;
          background: #ffffff;
          box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05);
          border-radius: 10px;
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
          color: $color-gray !important;
          text-align: left;
          line-height: 34px;
          * {
            color: $color-gray !important;
          }
        }

        /* White */
      }
    }
    .content {
      margin-top: 30px;
    }
  }
}

.create-lowongan {
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05),
    -16px -20px 30px rgba(0, 0, 0, 0.02), 8px 12px 35px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  padding: 30px 70px 50px 30px;
  h2.title {
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 40px;
    letter-spacing: 0.3px;
  }
}
