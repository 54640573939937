@import '../../styles/global.scss';
.detail-artikel {
  background: #f5f7fb;
  .main {
    background: #ffffff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05),
      -16px -20px 30px rgba(0, 0, 0, 0.02), 8px 12px 35px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    overflow: hidden;
    padding-bottom: 60px;
    margin-bottom: 50px;

    img {
      width: 100%;
    }
    h2.title-artikel {
      margin-left: 60px;
      margin-right: 60px;
      font-weight: 800;
      font-size: 30px;
      line-height: 130%;
      margin-bottom: 30px;
      text-align: center;
    }
    .breads {
      margin-left: 60px;
      margin-right: 60px;
    }
    p.subtitle {
      margin-left: 60px;
      margin-right: 60px;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      color: $color-gray;

      text-align: center;
    }
    .content {
      margin-left: 60px;
      margin-right: 60px;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 35px;
      * {
        color: $color-gray;
      }
      @include mobile {
        font-size: 14px;
        margin-bottom: 10px;
      }
    }
    .share {
      margin-left: 60px;
      margin-right: 60px;
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .icon-share {
        display: inline-block;
        border-radius: 50%;
        height: 40px;
        width: 40px;
        background: #eeecec;

        text-align: center;
        line-height: 50px;
        margin-left: 16px;
        i::before {
          position: relative;
          left: 0;
        }
      }
    }
  }
  .artikel-lainnya {
    /* White */

    background: #ffffff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05),
      -16px -20px 30px rgba(0, 0, 0, 0.02), 8px 12px 35px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    padding: 30px 50px 45px 60px;
    h2.title {
      font-style: normal;
      font-weight: 800;
      font-size: 20px;
      line-height: 140%;
      margin-bottom: 30px;
    }
    img {
      width: 100%;
      border-radius: 10px;
    }
    .subtitle {
      margin-top: 15px;
      font-weight: 800;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0.3px;
      @include mobile {
        font-size: 12px;
        margin-bottom: 10px;
      }
    }
  }
}
