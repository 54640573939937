@import '../../styles/global.scss';

.card-lowongan {
  position: relative;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05),
    -16px -20px 30px rgba(0, 0, 0, 0.02), 8px 12px 35px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  padding: 20px 30px 30px 30px;
  margin-top: 20px;
  .status {
    position: absolute;
    top: 20px;
    padding: 7px 20px;
    right: 30px;
    border-radius: 37px;
    color: white;
    &.aktif {
      background: green;
    }
    &.tidak {
      background: #eb5757;
    }
  }
  h2.title {
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 22px;
    /* identical to box height, or 110% */

    letter-spacing: 0.3px;
    margin-bottom: 25px;
  }
  .list1 {
    display: flex;

    * {
      margin-bottom: 15px;
    }
    flex-wrap: wrap;
  }

  .list3 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .updated {
      color: $color-gray;
    }
    .crud {
      span {
        margin-right: 20px;
      }
      .btn-a {
        font-weight: bold;
        font-size: 14px;
        margin-right: 10px;
      }
    }
  }
}
