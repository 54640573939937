.span-label {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin-right: 20px;
  span {
    margin-left: 10px;
  }
}
